import { debounce } from "../components/helpers/_observer-handler";
import handleMediaQueryChange from "../components/helpers/_media_query";

// Mobile Sidebar Menu
const isIosDevices = /iPad|iPhone|iPod/.test(navigator.userAgent);
const toggleSidebarHiddenVisible = (button, sidebar) => {
    const buttonSelector = document.querySelector(button);
    const sidebarSelector = document.querySelector(sidebar);

    if (!buttonSelector || !sidebarSelector) {
        return;
    }

    if (sidebarSelector.classList.contains("hidden")) {
        sidebarSelector.classList.remove("hidden");
    }

    const toggleSidebarClickHandler = () => {
        sidebarSelector.classList.toggle("translate-x-full");
        sidebarSelector.classList.toggle("translate-x-0");
        document.body.classList.toggle("sidebar-expanded");

        if (isIosDevices) {
            document.body.classList.toggle("fixed");
        }
    };

    if (!buttonSelector._clickHandlerAdded) {
        buttonSelector.addEventListener("click", toggleSidebarClickHandler);
        buttonSelector._clickHandlerAdded = true;
    }

    adjustTextScaleForFeaturedSports();
};

const toggleSidebarOnDesktop = (sidebar) => {
    const sidebarSelector = document.querySelector(sidebar);

    if (sidebarSelector.length === 0) {
        return;
    }

    if (sidebarSelector.classList.contains("translate-x-0")) {
        sidebarSelector.classList.toggle("translate-x-full");
        sidebarSelector.classList.toggle("translate-x-0");
        sidebarSelector.classList.add("hidden");
        document.body.classList.toggle("sidebar-expanded");

        if (isIosDevices) {
            document.body.classList.toggle("fixed");
        }
    }
};

const adjustTextScaleForFeaturedSports = () => {
    const featuredSport = document.querySelector("#featured-sports");

    if (featuredSport) {
        const containers = featuredSport.querySelectorAll("div");

        let shouldApplyClass = false;

        containers.forEach((container) => {
            const text = container.querySelector("p");

            text.classList.remove("smaller-size");
            text.classList.remove("one-word");

            if (text.clientHeight > container.clientHeight) {
                shouldApplyClass = true;
            }
        });

        if (shouldApplyClass) {
            containers.forEach((container) => {
                const text = container.querySelector("p");
                const trimmedText = text.textContent.trim();
                const words = trimmedText
                    .split(/\s+/)
                    .filter((word) => word.length > 0);

                if (words.length == 1) {
                    text.classList.add("one-word");
                }
                text.classList.add("smaller-size");
            });
        }
    }
};

const toggleMobileSidebarMenu = () => {
    const burgerMenuLocator = "#burgerMenu";
    const sidebarMenuLocator = "#sidebarMenu";
    const sidebarCloseButtonLocator = "#sidebarCloseButton";

    handleMediaQueryChange(
        window.addEventListener(
            "resize",
            debounce(adjustTextScaleForFeaturedSports),
        ),
        null,
    );
    toggleSidebarHiddenVisible(burgerMenuLocator, sidebarMenuLocator);
    toggleSidebarHiddenVisible(sidebarCloseButtonLocator, sidebarMenuLocator);
};

export const expandMobileSidebarMenu = () => {
    const sidebarMenuLocator = "#sidebarMenu";

    const mobileSidebarHandler = () => toggleMobileSidebarMenu();

    const desktopSidebarHandler = () => {
        toggleSidebarOnDesktop(sidebarMenuLocator);
    };

    handleMediaQueryChange(mobileSidebarHandler, desktopSidebarHandler);
};

const resetMobileNavScrollBehavour = () => {
    const mainNavigation = document.querySelector("#mainNavigation");
    const secondaryMenu = document.querySelector("#secondaryMenu");

    if (mainNavigation.classList.contains("condensed-state"))
        mainNavigation.classList.remove("condensed-state");
    if (mainNavigation.classList.contains("expanded-state"))
        mainNavigation.classList.remove("expanded-state");
    if (secondaryMenu?.classList.contains("condensed-state"))
        secondaryMenu.classList.remove("condensed-state");
    if (secondaryMenu?.classList.contains("expanded-state"))
        secondaryMenu.classList.remove("expanded-state");

    window.removeEventListener("scroll", setMobileNavStates);
};

let mobileLastScrollTop = 0;
const setMobileNavStates = () => {
    const mainNavigation = document.querySelector("#mainNavigation");
    const secondaryMenu = document.querySelector("#secondaryMenu");

    if (!mainNavigation) return;

    let scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (scrollTop > mobileLastScrollTop) {
        mainNavigation.classList.add("condensed-state");
        mainNavigation.classList.remove("expanded-state");
        secondaryMenu?.classList.add("condensed-state");
        secondaryMenu?.classList.remove("expanded-state");
    } else {
        mainNavigation.classList.remove("condensed-state");
        mainNavigation.classList.add("expanded-state");
        secondaryMenu?.classList.remove("condensed-state");
        secondaryMenu?.classList.add("expanded-state");
    }

    mobileLastScrollTop = scrollTop;
};

const mobileNavScrollBehavour = () => {
    window.addEventListener("scroll", setMobileNavStates);
};

export const condensedMobileNavigationBar = () => {
    handleMediaQueryChange(
        mobileNavScrollBehavour,
        resetMobileNavScrollBehavour,
    );
};

// Mobile Edition Menu
const toggleEditionMenu = (button, buttonArrow, editions) => {
    const buttonSelector = document.querySelector(button);
    const buttonArrowSelector = document.querySelector(buttonArrow);
    const editionsSelector = document.querySelector(editions);

    if (!buttonSelector || !editionsSelector || !buttonArrowSelector) {
        return;
    }

    const toggleeditionClickHandler = () => {
        editionsSelector.classList.toggle("open");
        buttonArrowSelector.classList.toggle("open");
    };

    if (!buttonSelector._clickHandlerAdded) {
        buttonSelector.addEventListener("click", toggleeditionClickHandler);
        buttonSelector._clickHandlerAdded = true;
    }
};

export const expandMobileEditionMenu = () => {
    const editionButtonLocator = "#mobileEditionButton";
    const editionsMenuLocator = "#mobileEditionsMenu";
    const editionButtonArrowLocator = "#mobileEditionButtonArrow";

    const mobileEditionsHandler = () => {
        toggleEditionMenu(
            editionButtonLocator,
            editionButtonArrowLocator,
            editionsMenuLocator,
        );
    };

    handleMediaQueryChange(mobileEditionsHandler, null);
};

// Mobile Sub Menu Condensed/Expanded State
const touchEventHandler = (secondaryMenu, thirdLevel) => {
    let startX = 0;
    let isSwiping = false;

    const secondaryMenuContainer = document.querySelector(secondaryMenu);
    const thirdLevelContainer = document.querySelector(thirdLevel);
    const thirdLevelTitle = thirdLevelContainer.querySelector("div");

    thirdLevelTitle.style.width = `${thirdLevelTitle.offsetWidth}px`;

    secondaryMenuContainer.addEventListener("touchstart", (event) => {
        startX = event.touches[0].clientX;
        isSwiping = true;
    });

    secondaryMenuContainer.addEventListener("touchmove", (event) => {
        if (!isSwiping) return;

        const currentX = event.touches[0].clientX;
        const deltaX = currentX - startX;

        if (deltaX < 0) {
            thirdLevelTitle.style.width = 0;
        }
    });

    secondaryMenuContainer.addEventListener("touchend", () => {
        isSwiping = false;
    });
};

const swipeGestureHandler = () => {
    const subMenuLocator = "#secondaryMenu";
    const subMenuItemsLocator = "#mobileSecondaryNavigation";
    const thirdLevelLocator = "#secondaryMenu nav #brandLogoAndName";

    const mobileSecondaryNavigation =
        document.querySelector(subMenuItemsLocator);
    const thirdLevelLogo = document.querySelector(thirdLevelLocator);

    if (!thirdLevelLogo) return;

    if (mobileSecondaryNavigation) {
        const isHorizontallyScrollable =
            mobileSecondaryNavigation.scrollWidth >
            mobileSecondaryNavigation.clientWidth;

        if (isHorizontallyScrollable) {
            touchEventHandler(subMenuLocator, thirdLevelLocator);
        }
    }
};

export const toggleMobileSubMenuLogo = () => {
    handleMediaQueryChange(swipeGestureHandler, null);
    handleMediaQueryChange(
        window.addEventListener("resize", debounce(swipeGestureHandler)),
        null,
    );
};

// Desktop Hover state of sports menu upon hovering more menu
const sportMenusHoverHandler = () => {
    const moreMenu = document.querySelector("#desktopMoreMenu");

    if (!moreMenu) {
        return;
    }

    moreMenu.addEventListener("mouseover", () =>
        toggleSportMenusTextColor(true),
    );
    moreMenu.addEventListener("mouseout", () =>
        toggleSportMenusTextColor(false),
    );

    function toggleSportMenusTextColor(isHovered) {
        const sportsMenu = moreMenu.previousElementSibling;

        if (!sportsMenu) {
            return;
        }

        if (isHovered) {
            sportsMenu.classList.add("text-primary-grey");
        } else {
            sportsMenu.classList.remove("text-primary-grey");
        }
    }
};

export const hoverStateDesktopSportMenu = () => {
    handleMediaQueryChange(null, sportMenusHoverHandler);
};

// Desktop navigation logo condensed state
let desktopLastScrollTop = 0;
const updateDesktopLogo = () => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    const tsnDesktopLogo = document.querySelector("#tsnLogo");

    if (!tsnDesktopLogo) {
        return;
    }

    if (scrollTop > desktopLastScrollTop) {
        tsnDesktopLogo.classList.remove(
            "bg-expanded-tsn-logo",
            "md:w-200px",
            "md:h-5",
        );
        tsnDesktopLogo.classList.add(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        );
    } else {
        tsnDesktopLogo.classList.add(
            "bg-expanded-tsn-logo",
            "md:w-200px",
            "md:h-5",
        );
        tsnDesktopLogo.classList.remove(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        );
    }
    desktopLastScrollTop = scrollTop;
};

const toggleTSNLogo = () => {
    let ticking = false;

    const scrollHandler = () => {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                updateDesktopLogo();
                ticking = false;
            });

            ticking = true;
        }
    };

    updateDesktopLogo();

    return scrollHandler;
};

const enableToggleTSNLogo = () => {
    window.addEventListener("scroll", toggleTSNLogo);
};

const disableToggleTSNLogo = () => {
    const tsnDesktopLogo = document.querySelector("#tsnLogo");

    if (
        tsnDesktopLogo &&
        tsnDesktopLogo.classList.contains(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        )
    ) {
        tsnDesktopLogo.classList.add(
            "bg-expanded-tsn-logo",
            "md:w-200px",
            "md:h-5",
        );
        tsnDesktopLogo.classList.remove(
            "bg-condensed-tsn-logo",
            "md:h-8",
            "md:w-8",
        );
    }
    window.removeEventListener("scroll", toggleTSNLogo);
};

export const condensedStateDesktopLogo = () => {
    handleMediaQueryChange(disableToggleTSNLogo, enableToggleTSNLogo);
};

// Desktop sub navigation height with teams handlers
const setSubNavItemsHeight = () => {
    const subNavContainers = document.querySelectorAll(
        "#desktopNavigation [data-has-dropdown]",
    );
    const MINIMUM_DROPDOWN_HEIGHT = 334;

    subNavContainers.forEach((subNavContainer) => {
        const items = subNavContainer.querySelector("[data-sub-menu]");
        const teams = subNavContainer.querySelector(
            "[show-sport-teams='true']",
        );
        const links = items?.querySelectorAll("li");

        if (teams) {
            if (teams.classList.contains("md:hidden")) {
                return;
            }
        }

        if (items) {
            if (items.classList.contains("md:hidden")) {
                return;
            }
        }

        if (links) {
            if ((links.length <= 5 && links.length != 0) & !teams) {
                links.forEach((link) => {
                    link.classList.add("md:w-full");
                });
            }
        }

        if (items && teams) {
            if (teams.offsetHeight < MINIMUM_DROPDOWN_HEIGHT) {
                items.classList.add("max-h-440px");
            } else {
                items.style.height = `${teams.offsetHeight}px`;
            }
        }
    });
};

const observeTeamsContainerHeight = () => {
    let prevHeight = null;
    const teamsContainers = document.querySelector(
        "#desktopNavigation [show-sport-teams='true']",
    );

    const teamsContainerResize = new ResizeObserver((entries) => {
        let rect = entries[0].contentRect;
        let height = rect.height;

        if (prevHeight !== null && height !== prevHeight) {
            setSubNavItemsHeight();
        }

        prevHeight = height;
    });

    if (teamsContainers) {
        teamsContainerResize.observe(teamsContainers);
    }

    setSubNavItemsHeight();
};

export const updateSubNavItemsHeight = () => {
    handleMediaQueryChange(null, observeTeamsContainerHeight);
};

// Desktop ticker navigation dropdown offset position & hover state
const toggleDropdown = (ticker, tickerNavigation, dropdown, isHovered) => {
    if (isHovered) {
        dropdown.classList.add("visible", "opacity-100");
        dropdown.classList.remove("invisible", "opacity-0");
        ticker.classList.add("text-white-js");
        tickerNavigation.classList.add("grey-text-js");
    } else {
        dropdown.classList.add("invisible", "opacity-0");
        dropdown.classList.remove("visible", "opacity-100");
        ticker.classList.remove("text-white-js");
        tickerNavigation.classList.remove("grey-text-js");
    }
};

const setupTickerHover = (ticker, tickerNavigation, dropdown) => {
    let isTickerHovered = false;

    const handleTickerMouseOver = () => {
        isTickerHovered = true;
        toggleDropdown(ticker, tickerNavigation, dropdown, true);
    };

    const handleTickerMouseOut = (event) => {
        if (
            !ticker.contains(event.relatedTarget) &&
            !dropdown.contains(event.relatedTarget)
        ) {
            isTickerHovered = false;
            toggleDropdown(ticker, tickerNavigation, dropdown, false);
        }
    };

    const handleDropdownMouseOver = () => {
        if (isTickerHovered) {
            toggleDropdown(ticker, tickerNavigation, dropdown, true);
        }
    };

    const handleDropdownMouseOut = (event) => {
        if (isTickerHovered && !ticker.contains(event.relatedTarget)) {
            toggleDropdown(ticker, tickerNavigation, dropdown, false);
        }
    };

    ticker.addEventListener("mouseover", handleTickerMouseOver);
    ticker.addEventListener("mouseout", handleTickerMouseOut);
    dropdown.addEventListener("mouseover", handleDropdownMouseOver);
    dropdown.addEventListener("mouseout", handleDropdownMouseOut);
};

export const nbaTickerHoverHandler = () => {
    const nbaTeamsTicker = document.querySelector("#nbaTeamsTicker");
    const nbaGlobalTicker = document.querySelector("#nbaGlobalTicker");
    const nbaTeamsDropdown = document.querySelector("#nbaTeamsDropdown");
    const nbaGlobalDropdown = document.querySelector("#nbaGlobalDropdown");
    const tickerNavigation = document.querySelector("#tickerNavigation");

    // The navigation ticker is sometimes not present on the page
    // This can happen when is_nba_sib is set to true and page.nba_ticker_menu is empty
    if (!tickerNavigation) {
        return;
    }

    if (nbaTeamsTicker && nbaTeamsDropdown) {
        setupTickerHover(nbaTeamsTicker, tickerNavigation, nbaTeamsDropdown);
    }

    if (nbaGlobalTicker && nbaGlobalDropdown) {
        setupTickerHover(nbaGlobalTicker, tickerNavigation, nbaGlobalDropdown);
    }
};

const alignTickerDropdownToLeft = () => {
    const tickerNav = document.querySelector("#tickerNavigation");

    // The navigation ticker is sometimes not present on the page
    // This can happen when is_nba_sib is set to true and page.nba_ticker_menu is empty
    if (!tickerNav) {
        return;
    }

    const tickerNavRect = tickerNav.getBoundingClientRect();
    const nbaTeamsDropdown = document.querySelector("#nbaTeamsDropdown");
    const nbaGlobalDropdown = document.querySelector("#nbaGlobalDropdown");

    if (!nbaTeamsDropdown || !nbaGlobalDropdown) {
        return;
    }

    nbaTeamsDropdown.style.left = 0;
    nbaTeamsDropdown.style.transform = `translateX(${tickerNavRect.left}px)`;
    nbaGlobalDropdown.style.transform = `translateX(${tickerNavRect.left}px)`;
};

export const updateTickerNavDropdownPosition = () => {
    handleMediaQueryChange(null, alignTickerDropdownToLeft);
    handleMediaQueryChange(
        null,
        window.addEventListener("resize", debounce(alignTickerDropdownToLeft)),
    );
};

let overflowViewportWidth = null; // Store the viewport width where overflow was detected

const desktopOverflowNavDetector = () => {
    const desktopNavigation = document.querySelector("#desktopNavigation");
    const desktopNavigationLogo = document.querySelector(
        "#desktopNavigationLogo",
    );
    const desktopLogoDivider = document.querySelector("#desktopLogoDivider");
    const burgerMenu = document.querySelector("#burgerMenu");
    const DESKTOP_NAVIGATION_PADDING = 32;

    if (!desktopNavigation || !desktopNavigationLogo) return;

    let desktopNavigationWidth =
        desktopNavigation.offsetWidth + desktopNavigationLogo.offsetWidth;
    let documentWidth =
        document.documentElement.clientWidth - DESKTOP_NAVIGATION_PADDING;

    if (desktopNavigationWidth > documentWidth) {
        if (documentWidth !== overflowViewportWidth) {
            // Only toggle if it's the first time we detect overflow
            overflowViewportWidth = documentWidth;

            desktopNavigation.classList.remove("md:flex");
            burgerMenu.classList.remove("md:hidden");
            desktopLogoDivider.classList.remove("md:block");

            // Burger Toggle
            toggleMobileSidebarMenu();

            // Edition Picker
            const editionButtonLocator = "#mobileEditionButton";
            const editionsMenuLocator = "#mobileEditionsMenu";
            const editionButtonArrowLocator = "#mobileEditionButtonArrow";

            toggleEditionMenu(
                editionButtonLocator,
                editionButtonArrowLocator,
                editionsMenuLocator,
            );
        }
    } else {
        if (documentWidth >= overflowViewportWidth) {
            overflowViewportWidth = null;

            desktopNavigation.classList.add("md:flex");
            burgerMenu.classList.add("md:hidden");
            desktopLogoDivider.classList.add("md:block");
        }
    }
};

export const overflowDetector = () => {
    handleMediaQueryChange(desktopOverflowNavDetector(), null);
    handleMediaQueryChange(
        window.addEventListener("resize", desktopOverflowNavDetector),
        null,
    );
};

// Desktop primary navigation dropdown offset position
const secondaryMenuDropdownPlacement = () => {
    const secondaryNav = document.querySelector("#desktopMoreMenu");
    const secondaryNavItems = document.querySelectorAll(
        "#desktopMoreMenu > div",
    );

    secondaryNavItems.forEach((item) => {
        const dropdown = item.querySelector("div");
        const dropdownRect = dropdown.getBoundingClientRect();
        const secondaryNavRect = secondaryNav.getBoundingClientRect();

        if (dropdown.classList.contains("md:-translate-x-1/2")) {
            const dropdownRectRight = dropdownRect.right;
            const secondaryNavRectRight = secondaryNavRect.right;

            if (dropdownRectRight > secondaryNavRectRight) {
                dropdown.style.left = `calc(50% - ${dropdownRectRight - secondaryNavRectRight}px)`;
            } else {
                dropdown.style.left = "50%";
            }
        }
    });
};

let expandedDropdownPositions = [];
let condensedDropdownPositions = [];

const getExpandedDropdownsPosition = () => {
    const primaryNav = document.querySelector("#headerNavigation");
    const primaryNavItems = document.querySelectorAll(
        "#desktopPrimaryMenu > div",
    );

    primaryNavItems.forEach((item, id) => {
        if (!item.classList.contains("md:hidden")) {
            const dropdown = item.querySelector("div");
            const dropdownRect = dropdown.getBoundingClientRect();
            const primaryNavRect = primaryNav.getBoundingClientRect();

            if (dropdown.classList.contains("md:-translate-x-1/2")) {
                const expandedAndCondensedDifference = 168; // difference of expanded and condensed logo
                const navigationPaddings = 23; // navigation is using 16px padding both sides
                const dropdownRectLeft = Math.round(dropdownRect.left);
                const primaryNavRectLeft = Math.round(primaryNavRect.left);
                const expandedPosition = dropdownRectLeft - primaryNavRectLeft;
                const condensedPosition =
                    dropdownRectLeft -
                    expandedAndCondensedDifference -
                    primaryNavRectLeft;
                const extraLeftSpacing =
                    (window.innerWidth - primaryNav.offsetWidth) / 2 -
                    navigationPaddings;

                if (expandedPosition < primaryNavRectLeft - extraLeftSpacing) {
                    expandedDropdownPositions.push({
                        id: id,
                        position: expandedPosition,
                    });
                }

                if (condensedPosition < primaryNavRectLeft - extraLeftSpacing) {
                    condensedDropdownPositions.push({
                        id: id,
                        position: condensedPosition,
                    });
                }
            }
        }
    });

    condensedDropdownPositions.forEach((item) => {
        const found = expandedDropdownPositions.some(
            (condensedItem) => condensedItem.id === item.id,
        );
        if (!found) {
            expandedDropdownPositions.push({ id: item.id, position: 0 });
        }
    });
};

const primaryMenuDropdownPlacement = () => {
    const primaryNavItems = document.querySelectorAll(
        "#desktopPrimaryMenu > div",
    );

    primaryNavItems.forEach((item, index) => {
        if (!item.classList.contains("md:hidden")) {
            const dropdown = item.querySelector("div");
            const tsnLogo = document.querySelector(
                "#headerNavigation > div > a",
            );
            const condensedLogo = tsnLogo.classList.contains(
                "bg-condensed-tsn-logo",
            );
            const expandedLogo = tsnLogo.classList.contains(
                "bg-expanded-tsn-logo",
            );

            if (condensedLogo) {
                const condensedDropdownData = condensedDropdownPositions.find(
                    (obj) => obj.id === index,
                );
                if (condensedDropdownData) {
                    dropdown.style.setProperty(
                        "left",
                        `calc(50% - ${condensedDropdownData.position}px)`,
                        "important",
                    );
                }
            }

            if (expandedLogo) {
                const expandedDropdownData = expandedDropdownPositions.find(
                    (obj) => obj.id === index,
                );
                if (expandedDropdownData) {
                    dropdown.style.setProperty(
                        "left",
                        `calc(50% - ${expandedDropdownData.position}px)`,
                        "important",
                    );
                }
            }
        }
    });
};

export const updatePrimaryNavDropdownPosition = () => {
    handleMediaQueryChange(null, getExpandedDropdownsPosition);
    handleMediaQueryChange(null, primaryMenuDropdownPlacement);
    handleMediaQueryChange(null, secondaryMenuDropdownPlacement());
    handleMediaQueryChange(
        null,
        window.addEventListener(
            "scroll",
            debounce(primaryMenuDropdownPlacement),
        ),
    );
};
